import React, { Component } from "react";

export class SellToWebAdminInputComponent extends Component {
    constructor(props) {
        super(props);

        this.onBlur = this.onBlur.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
    }

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(event, this.props.id, event.target.value, this.props.additionalData);
        }
    }

    onTextChange(event) {
        switch (this.props.inputType) {
            case "money":
            case "decimal":
            case "percent":
                const regexDecimal = /^[-|\d](\d|,)*(\.)?(\d{1,2})?$/;
                if (event.target.value === "" || regexDecimal.test(event.target.value)) {
                    if (this.props.onChange) {
                        this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                    }
                }
                break;

            case "number":
                const regexNumber = /^(\d|,)*$/;
                if (event.target.value === "" || regexNumber.test(event.target.value)) {
                    if (this.props.onChange) {
                        this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                    }
                }
                break;

            default:
                if (this.props.onChange) {
                    this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                }
                break;
        }
    }

    onKeyDown(event) {
        if (this.props.enterToTab && event.keyCode === 13) {
            event.preventDefault();
            const inputs = Array.from(document.querySelectorAll("input")); //Get array of inputs
            let nextInput = inputs[inputs.indexOf(document.activeElement) + 1];//get index of input after the current input
            nextInput.focus(); //focus new input
        }
        else if (this.props.onLookupClick && event.keyCode === 13) {
            this.props.onLookupClick(this.props.id, this.props.value, this.props.additionalData);
        }
        else if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.additionalData);
        }
    }

    onClearClick(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, "", this.props.additionalData);
        }
    }

    onLookupClick(event) {
        if (this.props.onChange) {
            this.props.onLookupClick(this.props.id, this.props.value, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        let labelSpan = this.props.labelSpan ?? "col-form-label";
        let controlSpan = this.props.controlSpan ?? "col-auto";
        let placeHolder = this.props.placeholder ?? this.props.label;
        if (this.props.className) {
            colSpan += " " + this.props.className;
        }
        if (this.props.labelClassName) {
            labelSpan += " " + this.props.labelClassName;
        }
        if (this.props.controlClassName) {
            controlSpan += " " + this.props.controlClassName;
        }

        let value = (this.props.value !== null && this.props.value !== undefined) ? this.props.value : "";
        if (value && (this.props.inputType === "money"
            || this.props.inputType === "decimal"
            || this.props.inputType === "percent")) {

            value = value.toString().replace("(", "-").replace(")", "");
        }

        return <div className={"admin-control " + colSpan}>
            <div className="input-group">
                {
                    this.props.label
                        ? <label className={"admin-label " + labelSpan} htmlFor={this.props.id}>
                            {this.props.label}{
                                this.props.tooltip
                                    ? <div className="admin-tooltip ps-1 pt-1" data-display="static">
                                        <span className="far fa-info-circle" data-display="static" />
                                        <div className={"content "} data-display="static">
                                            {this.props.tooltip}
                                        </div>
                                    </div>
                                    : null
                            }:
                            {
                                this.props.isRequired
                                    ? <span className="text-danger">*</span>
                                    : null
                            }
                        </label>
                        : null
                }
                <div className={controlSpan}>
                    <div className="input-group">
                        {
                            this.props.inputType === "money"
                                ? <span className="input-group-text">$</span>
                                : null
                        }
                        <input id={this.props.id} maxLength={this.props.maxLength} value={value} placeholder={placeHolder} disabled={this.props.disabled} readOnly={this.props.readOnly}
                            className={"form-control " + (this.props.inputClassName ?? "")}
                            onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} onBlur={(event) => this.onBlur(event)} />
                        {
                            this.props.inputType === "percent" || this.props.inputType === "percent_2"
                                ? <span className="input-group-text">%</span>
                                : null
                        }
                        {
                            this.props.onLookupClick
                                ? <button className="btn btn-secondary" type="button" disabled={this.props.disabled || this.props.readOnly} onClick={(event) => this.onLookupClick(event)}>
                                    <i className="far fa-search" />
                                </button>
                                : null
                        }
                        {
                            this.props.helperText
                                ? <span style={{ "position": "absolute", "color": "#757575", "right": "40px", "top": "8px", "fontStyle": "italic", "zIndex": "999", "fontSize": "12px" }}>{this.props.helperText}</span>
                                : null
                        }
                        {
                            this.props.hideClear !== true
                                ? <button className="btn btn-secondary" type="button" disabled={this.props.disabled || this.props.readOnly} onClick={(event) => this.onClearClick(event)} tabIndex="-1">
                                    <span className="far fa-times" />
                                </button>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}